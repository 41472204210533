import {useLocation, useNavigate,Navigate,redirect} from "react-router-dom";

export default {

    withAuthrization(WrappedComponent) {
        return function Authrization() {
            const location = useLocation()
            const qrcode = localStorage.getItem('qrcode')

            const state = location.state

            if(state== null){
                return <Navigate to={`/${qrcode}`} replace={true}/>
            }



            return <WrappedComponent/>;
        }
    },

    withation(WrappedComponent) {
        return function Aation() {
            return <WrappedComponent/>;
        }
    }

}

