import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import router from './config/route'
import i18n from 'i18next';


import './index.css'
import '../src/utils/fixed'
import '../src/locales/i18n'

window.i18n = i18n;


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
    <RouterProvider router={router} />
  // </React.StrictMode>
)







