import React,{ Suspense, lazy } from 'react'
import { createBrowserRouter } from 'react-router-dom'
import C from '../components/withAuthrization'

function authComponent(Component) {
  const WrappedComponent = C.withAuthrization(Component)
  return (
    <Suspense fallback={<div>loading...</div>}>
      <WrappedComponent />
    </Suspense>
  )
}

function purComponent(Component) {
  const WrappedComponent = C.withation(Component)
  return (
      <Suspense fallback={<div>loading...</div>}>
        <WrappedComponent />
      </Suspense>
  )
}
// 路由组件推荐使用懒加载的方式引入
const Home = purComponent(lazy(() => import('../pages/Home')))
const Bank = purComponent(lazy(() => import('../pages/Bank')))
const Result = authComponent(lazy(() => import('../pages/Result')))
const Cardverfiy = purComponent(lazy(() => import('../pages/Cardverfiy')))
const Error = purComponent(lazy(() => import('../pages/Error')))


const routes = [
  {
    path: '/', //path 对应 Route的path
    element: Error, //element 对应Route的element
    // children 对应 嵌套路由
    exact: true //精确匹配
  },
  {
    path: '/:id', //path 对应 Route的path
    element: Home, //element 对应Route的element
    // children 对应 嵌套路由
    exact: true //精确匹配
  },
  // {
  //   path: '/home/:id', //path 对应 Route的path
  //   element: Home, //element 对应Route的element
  //   exact: true
  // },
  {
    path: '/bank',
    element: Bank,
  },
  {
    path: '/result',
    element: Result,
  },
  {
    path: '/cardverify',
    element: Cardverfiy,
  },
]

export default createBrowserRouter(routes)
