// rem适配方式一:
const fontSize = (document.documentElement.clientWidth * 100) / 375
document.documentElement.style.fontSize = fontSize + 'px'

// 处理1px边框的问题
// 让布局视口等比变大
// const viewport = document.querySelector('meta[name=viewport]')
// const scale = 1 / window.devicePixelRatio
// viewport.setAttribute('content', 'width=device-width, initial-scale=' + scale)
// // 让根标签等比变大,从而让盒子等比变大
// document.documentElement.style.fontSize = fontSize * window.devicePixelRatio + 'px'
